<template>
  <div>

        <Pane>
          <div style="position: absolute;right: 32px;bottom: 16px;">
            <a-button style="margin-right: 10px;" @click="planFill">填报计划</a-button>
            <a-button @click="planManagement">计划管理</a-button>
          </div>
        </Pane>

    <a-card class="container">
      <!-- <div class="card" >
        <div class="item">
          <div class="title">
            <span>当月总计划(万元)</span>
            <span class="extra">
              <HistoryList :list='historyAnnualData' />
            </span>
          </div>
          <div
            class="value"
          >
                   <Money :money="historyProfit" />
          </div>

        </div>
        <div class="item">
          <div class="title">
            <span>当月总产值(万元)</span>
            <span class="extra">
              <YearList :list='statistic' />
            </span>
          </div>
          <div
            class="value"
          >
                   <Money :money="statisticProfit" />
          </div>

        </div>
        <div class="item" >
          <div class="title">
               <span>当年总产值(万元)</span>
            <span class="extra">
              <MonthList  title='当月完成利润' :list="monthObj.prjCheckList ? monthObj.prjCheckList : []" />
            </span>
          </div>
          <div
            class="value"
          >
              <Money :money="monthObj.profit" />
          </div>
        </div>
      </div>

      <Padding /> -->

      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">

            <a-form-model-item>
              <a-input
                placeholder="项目编码"
                v-model="form.designCode"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="项目名称" v-model="form.name" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
        rowKey="id"
      >


        <a-table-column title="项目编码" data-index='designCode' align='center' />
        <a-table-column title="项目名称" data-index='name' />
        <a-table-column title="合同金额(万)">
          <template slot-scope="text">
             <Money :money="text.contractAmount" />
          </template>
        </a-table-column>
        <a-table-column title="设计费(万)">
          <template slot-scope="text">
             <Money :money="text.designAmt" />
          </template>
        </a-table-column>
        <a-table-column title="上年末进度" align='right'>
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.lastYearProgress }}%</span>
          </template>
        </a-table-column>
        <a-table-column title="累计项目进度" align='right'>
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.totalProgress }}%</span>
          </template>
        </a-table-column>
        <a-table-column title="当年确认产值(万)">
          <template slot-scope="text">
             <Money :money="text.currentYearIncome" />
          </template>
        </a-table-column>
        <a-table-column title="累计确认产值(万)">
          <template slot-scope="text">
             <Money :money="text.totalIncome" />
          </template>
        </a-table-column>
        <!-- <a-table-column align="center" title="操作" width="120px">
          <template slot-scope="text">
            <div>
              <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="$router.push($route.path + '/detail?id=' + text.id)"
              >查看</a>
                    <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
              >编辑</a>
              </a-space>
            </div>
          </template>
        </a-table-column> -->
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
// import HistoryList from "./history-list.vue";
// import MonthList from "./month-list.vue";
// import YearList from "./year-list.vue";
import moment from 'moment'


function fetchHistoryAnnualData() {
  return request({
    url: "/market-service/epc/view/profit/historyAnnualData/list"
  });
}

function fetchStatistic({year}) {
  return request({
    url: "/market-service/epc/prg/check/bus/list?date=" + year
  });
}

function fetchList(params) {
  return request({
    url: '/market-service/epc/view/progress/prj/list',
    params,
  });
}

export default {
  mixins: [ watermark],

  components: {
    // MonthList,
    // YearList,
    // HistoryList,
  },

  data() {
    return {
      historyAnnualData: [],
      historyProfit: 0,
      statistic: [],
      statisticProfit: 0,
      month: moment().format("YYYY-MM"),

      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    monthObj() {
        const item = this.statistic.find(item => item.date === this.month);
        console.log('item',item)
        return item ? item : {}
    }
  },

  mounted() {
    fetchStatistic({
      year: new Date().getFullYear()
    }).then(res => {
      this.statistic = Object.freeze(res);
      if (Array.isArray(res) && res.length > 0) {
        this.statisticProfit = res[res.length -1 ].profit
      }
    })
    fetchHistoryAnnualData().then(res => {
      this.historyAnnualData = res;
      res.forEach(element => {
        this.historyProfit = this.historyProfit + element.profit;
      });
    })
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      this.loading = true;

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },


    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    planFill() {
      this.$router.push("/epc/process/plan-fill")
    },
    planManagement() {
      this.$router.push("/epc/process/plan-management")
    }
  }
};
</script>
<style lang="less" scoped>
.card {
  display: flex;

  .item {
    border: 2px solid #f0f0f0;
    margin-right: 12px;
    padding: 12px;
    border-radius: 2px;
    min-width: 240px;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .extra {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .value {
      font-size: 2em;
      color: #1890ff;
    }

    .setting {
      position: absolute;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
    }
  }

  .active {
    border-color: #1890ff;
  }
}
</style>